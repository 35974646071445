<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <!-- <h2 class="titulo-links-escolha">Calendário Letivo {{item.ano}}  </h2> -->
    <div class="pl-0 md:col-11 lg:col-9 xl:col-8" v-if="ano != null">
      <pm-Dropdown
        dropdown
        placeholder="Selecione o Calendário "
        v-model="calSelec"
        :options="todosCalendarios"
        @change="selectCal()"
        optionLabel="nome"
      />
    </div>

    <div v-if="calSelec">
      <h2 v-if="calSelec.situacao === 0" class="titulo-links-escolha">
        {{ calSelec.nome }} (fechado)
      </h2>
      <h2 v-else class="titulo-links-escolha">{{ calSelec.nome }}</h2>
    </div>

    <div v-if="calSelec" class="pm-card">
      <br />
      <div class="row">
        <div class="col-md-12" style="display: inherit">
          <div class="legenda-item">
            <div class="col-md-1" style="width: 16px; padding-top: 5px">
              <div
                style="width: 16px; height: 16px; background-color: #2279e5"
              ></div>
            </div>
            <div class="col-md-11">
              <h5 style="margin-left: 5px">Dias letivos</h5>
            </div>
          </div>
          <div class="legenda-item" v-for="n in this.legenda" :key="n.periodo">
            <div class="col-md-1" style="width: 16px; padding-top: 5px">
              <div
                :style="
                  'width:16px;height:16px;background-color:#' + n.colorpicker
                "
              ></div>
            </div>
            <div class="col-md-11">
              <h5 style="margin-left: 5px">{{ n.periodo }}</h5>
            </div>
          </div>
        </div>

        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Janeiro"
          mes_marcar="janeiro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Fevereiro"
          mes_marcar="fevereiro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Março"
          mes_marcar="marco"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Abril"
          mes_marcar="abril"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Maio"
          mes_marcar="maio"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Junho"
          mes_marcar="junho"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Julho"
          mes_marcar="julho"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Agosto"
          mes_marcar="agosto"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Setembro"
          mes_marcar="setembro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Outubro"
          mes_marcar="outubro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Novembro"
          mes_marcar="novembro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
        <calendario-component
          v-if="calSelec"
          class="col-md-4 responsive-calendario"
          mes="Dezembro"
          mes_marcar="dezembro"
          @marcar="marcar"
          :ativar_calendario="1"
          :item="calSelec"
          :acesso="acesso"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Calendario } from "@/class/calendario";
import { Configuracao } from "@/class/configuracao";
import calendarioComponent from "@/components/calendario/calendario.vue";

export default defineComponent({
  components: {
    calendarioComponent,
  },
  data() {
    return {
      home: {
        icon: "pi pi-home",
        to: "/ambiente-coordenacao/calendario-coordenador",
      },
      items_bread: [{ label: "Calendário" }],
      item: [],
      legenda: [],
      configuracao: [],
      configuracao: {
        calendario_letivos_id: 0,
        tipo_ano: "Bimestre",
        tipo_ano_nome_exib: "Período",
        data_inicial_matricula: null,
        data_final_matricula: null,

        b1_inicio: null,
        b1_final: null,
        b2_inicio: null,
        b2_final: null,
        b3_inicio: null,
        b3_final: null,
        b4_inicio: null,
        b4_final: null,
        test: null,

        colorpicker_1b: "2279e5",
        colorpicker_2b: "2279e5",
        colorpicker_3b: "2279e5",
        colorpicker_4b: "2279e5",
      },
      ano: null,
      acesso: {
        visualizar: 1,
        cadastrar: 0,
        editar: 0,
        deletar: 0,
      },
      calSelec: null,
      todosCalendarios: [],
      calFiltrado: null,
    };
  },
  computed: {
    item() {
      return this.item;
      //listarTudo();
    },
  },

  methods: {
    async selectCal() {
      //receber as colores do periodo
      this.legenda[0].colorpicker = this.calSelec.colorpicker_1b;
      this.legenda[1].colorpicker = this.calSelec.colorpicker_2b;
      this.legenda[2].colorpicker = this.calSelec.colorpicker_3b;
      this.legenda[3].colorpicker = this.calSelec.colorpicker_4b;
    },
    async listarTudo() {
      try {
        this.legenda = [];
        const todosCalendarios = await Calendario.buscarTodosCalendarios(this.TheAno);
        this.todosCalendarios = todosCalendarios.data;
        const data = await Calendario.calendarioAtual();

        const dt = await Configuracao.obtemOne(data.data.id);

        if (dt.data.length > 0 || dt.data.id) {
          let lg = [];
          lg = {
            colorpicker: dt.data.colorpicker_1b,
            periodo: "1° " + dt.data.tipo_ano_nome_exib,
          };
          this.legenda.push(lg);

          lg = [];
          lg = {
            colorpicker: dt.data.colorpicker_2b,
            periodo: "2° " + dt.data.tipo_ano_nome_exib,
          };

          this.legenda.push(lg);
          lg = [];
          lg = {
            colorpicker: dt.data.colorpicker_3b,
            periodo: "3° " + dt.data.tipo_ano_nome_exib,
          };

          this.legenda.push(lg);
          lg = [];
          lg = {
            colorpicker: dt.data.colorpicker_4b,
            periodo: "4° " + dt.data.tipo_ano_nome_exib,
          };

          this.legenda.push(lg);

          this.configuracao = dt.data;
        }

        this.item = data.data;
        this.ano = this.item[0].ano;
      } catch (e) {
        // console.log(e);
      }
    },
  },
  beforeMount() {
    this.TheAno = sessionStorage.getItem("anoSelecionado");
    this.listarTudo();
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.semana {
  background-color: rgb(239 239 239);
  text-align: center;
}
.font-semana {
  font-size: 10px;
}
.dias {
  text-align: center;
}
.dia-marcado {
  background-color: #4182c5 !important;
  color: #fff;
}
</style>
